import React, { createContext, useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';
import AppSettings from "./AppSettings.json";

const baseUrl = AppSettings.CONCESSIONS_API;
const authUrl = AppSettings.AUTH_API;
const baseKey = AppSettings.CONCESSIONS_KEY;
const authKey = AppSettings.AUTH_KEY;
const urlBase = AppSettings.API_LOGIN_URL;
const urlKey = AppSettings.API_LOGIN_KEY;
const UserContext = createContext();

const UserContextProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState(null);
    const [userToken, setUserToken] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [currentTenant, setCurrentTenant] = useState(null);
    const [loading, setLoading] = useState(true);

    const { instance, accounts } = useMsal();

    useEffect(() => {
		const fetchUser = async () => {
			//console.log("Fetch users called")
			if (accounts.length > 0) {
				let token;
				axios.get(authUrl + "CreateApiJwt", {
					headers: {
						'ocp-apim-subscription-key': authKey
					}
				}).then(async (response) => {
					//setUserToken("eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IktRMnRBY3JFN2xCYVZWR0JtYzVGb2JnZEpvNCIsImtpZCI6IktRMnRBY3JFN2xCYVZWR0JtYzVGb2JnZEpvNCJ9.eyJhdWQiOiI4ZTVmMzQxMy00ZDA3LTRiMjctOTk3OS0xOTcxYTBhNDhjOTMiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9iZWZjZjRmOS0xYTVhLTQ0NTgtOTNlMC1lYTE3MzczYTY4Y2QvIiwiaWF0IjoxNzIyODgyNjc0LCJuYmYiOjE3MjI4ODI2NzQsImV4cCI6MTcyMjg4NjU3NCwiYWlvIjoiRTJkZ1lEaWI4dHlKTVh1cGwydEU3cWtsSmRPZUFBQT0iLCJhcHBpZCI6ImQzN2JkNjM3LTE3ZTgtNGViNC04MmZjLTEzNDgyMzkzYzAzOSIsImFwcGlkYWNyIjoiMSIsImlkcCI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0L2JlZmNmNGY5LTFhNWEtNDQ1OC05M2UwLWVhMTczNzNhNjhjZC8iLCJvaWQiOiIwNGY0MjM4NC0zMjcxLTRhYWUtYmM1OC1iM2YxNWMxOWJkN2UiLCJyaCI6IjAuQVMwQS1mVDh2bG9hV0VTVDRPb1hOenBvelJNMFg0NEhUU2RMbVhrWmNhQ2tqSlAzQUFBLiIsInN1YiI6IjA0ZjQyMzg0LTMyNzEtNGFhZS1iYzU4LWIzZjE1YzE5YmQ3ZSIsInRpZCI6ImJlZmNmNGY5LTFhNWEtNDQ1OC05M2UwLWVhMTczNzNhNjhjZCIsInV0aSI6Imh6UnFUaFNZM0VtNGZZRlFIRUpiQUEiLCJ2ZXIiOiIxLjAifQ.Am6X5WyjiCr833vijgplr8Ck66zw8xOrBxSNoTinbaNLuPkkO9Q2YwG83XZi7DS8hwSFAGdBgO6N40bXaH2fIY8WfLI2tVLcXotO29xO_MfwxlgnciBDGyco9Vj0P5yVhFIhypGBHntH18rf5Mpm7h4gzW_z0YXTdwVRYQ3OyXmVLCqJFC_3eDPdSLcoL3vFPUR-jhhxQ_87dZZbGYLyHWAQ9Fi3i5aQ-BbuymH9erHqu3XtAYD7r_u8-PSid26f2Do5K8ZxLJReAZrsuM1PdO9u3cw5RjOjlWV-dHVXb4HQnqQKG2KLZYqOxQVAPwHLTIBZJ6ZC1ZmMa_BTt-Lg0g");
					setUserToken(response.data)
					try {
						//console.log(baseKey)
						await axios.get(`${baseUrl}users/${accounts[0]?.username}/tenants`, {
							headers: {
								Authorization: 'Bearer ' + response.data,
								'ocp-apim-subscription-key': baseKey
							}
						}).then( res => {
							let uObj = { ...accounts[0] }
							if (uObj.username.toUpperCase().endsWith("@FLYPITTSBURGH.COM")) {
								uObj.acaaUser = true;
							}
							else if (uObj.username.toUpperCase().endsWith("@AIRPORTLOGISTICS.ORG")) {
								uObj.logisticsUser = true;
							}
							//console.log(baseKey)
							axios.put(`${urlBase}users/${accounts[0]?.username}/loginTime`+urlKey, {
								headers: {
									Authorization: 'Bearer ' + response.data,
									'ocp-apim-subscription-key': baseKey
								}
							})
							uObj.tenantInfo = res?.data;
							setCurrentTenant(res?.data[0]?.tenant?.tenantId);
							setUserInfo(uObj);
							setLoading(false);
						})
					}
					catch (exception) {
						setLoadingError(exception)
					}

				})
			}
		};

		fetchUser();
	}, [accounts]);
    const parseJwt = (token) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
        return JSON.parse(jsonPayload);
    };

    const checkTokenExpiration = async () => {
        const decoded = parseJwt(userToken);
        const expiry = new Date(decoded.exp * 1000);
		//console.log("Expiry Date", expiry, "Current Time", new Date())
        return expiry < new Date();
    };

    const apiCallRequest = async (url, uri, body, operation, token) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'ocp-apim-subscription-key': baseKey
                }
            };

            switch (operation?.toUpperCase()) {
                case 'GET':
                    return await axios.get(url + uri, config);
                case 'PUT':
                    return await axios.put(url + uri, body, config);
                case 'POST':
                    return await axios.post(url + uri, body, config);
                case 'DELETE':
                    return await axios.delete(url + uri, { ...config, data: body });
                default:
                    return await axios.get(url + uri, config);
            }
        } catch (error) {
            console.error("API call failed", error);
            throw error;
        }
    };

    const apiCall = async (uri, body, operation, useLocal) => {
        let url = baseUrl;
        if (useLocal) {
            url = 'http://localhost:7255/api/';
        }

        if (await checkTokenExpiration()) {
			//console.log("Token Expired... Generating new token")
            try {
                const { data: newToken } = await axios.get(`${authUrl}CreateApiJwt`, {
                    headers: {
                        'ocp-apim-subscription-key': authKey
                    }
                });
                setUserToken(newToken);
                return apiCallRequest(url, uri, body, operation, newToken);
            } catch (error) {
                console.error("Token refresh failed", error);
                throw error;
            }
        } else {
            return apiCallRequest(url, uri, body, operation, userToken);
        }
    };

    if (loadingError) {
        return (
            <UserContext.Provider value={{ ...userInfo, tenant: currentTenant, setTenant: setCurrentTenant }}>
                <h1 style={{ marginTop: '10%', textAlign: 'center' }}>
                    Your account is not currently associated with a tenant.
                    An active user with an existing tenant will need to invite you.
                </h1>
            </UserContext.Provider>
        );
    } else if (loading && accounts.length > 0) {
        return (
            <UserContext.Provider value={{ ...userInfo, tenant: currentTenant, setTenant: setCurrentTenant }}>
                <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
            </UserContext.Provider>
        );
    } else {
        return (
            <UserContext.Provider value={{ ...userInfo, tenant: currentTenant, setTenant: setCurrentTenant, apiCall }}>
                {children}
            </UserContext.Provider>
        );
    }
};

export { UserContext, UserContextProvider };
