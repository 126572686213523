import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../UserContext";
import { ConcessionsContext } from '../service/ConcessionsContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

const unitAddMsg = "Unit Added.";
const unitFailMsg = "Failed. Invalid data or record exists";
const unitUpdateMsg = "Unit Updated.";
const unitUpdateFailMsg = "Failed. Invalid Data";
const unitDeleteMsg = "Unit removed.";
const unitDeleteFail = "Failed to remove unit.";
const unitAssignedFail = "Failed to remove unit. This unit is assigned to a tenant.";

export const Units = () => {
	const [units, setUnits] = useState([]);
	const [unitToDelete, setUnitToDelete] = useState();
	const [unitToDeleteId, setUnitToDeleteId] = useState();
	const [locationId, setLocationId] = useState('');
	const [locations, setLocations] = useState();
	const [sqft, setSqft] = useState();
	const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
	const [unitToAdd, setUnitToAdd] = useState('');
	const [displayModal, setDisplayModal] = useState(false);
	const [newUnit, setNewUnit] = useState({ unitName: '', location: '', sqft: '1' });
	const [newUnitClass, setNewUnitClass] = useState({unitNameError : false , locationError: false, sqftError: false});
	
	const user = useContext(UserContext);
	const global = useContext(ConcessionsContext);

	useEffect(() => {
		user.apiCall('units').then(res => {
			setUnits(res.data);
		})
		user.apiCall('locations').then(res => {
			setLocations(res.data);
		})
	}, []);


	const buttonBodyTemplate = (rowData) => {
		if (rowData.unitId) {
			return (
				<Button tooltip="Delete Unit"
					tooltipOptions={{ position: 'left' }}
					onClick={() => {
						setUnitToDeleteId(rowData.unitId);
						setUnitToDelete(rowData.unitName);
						setDisplayDeleteDialog(true);
					}}
					label={<i className="pi pi pi-times"></i>}
					className="p-button-rounded p-button-primary"
				/>
			)
		}
	}

	useEffect(()=>{
		console.log(newUnitClass)
		console.log(newUnit)
	}, [newUnitClass])
	const addUnit = () => {
		const body = {
			UnitName: newUnit.unitName.trim(),
			LocationId: newUnit.location,
			Sqft: newUnit.sqft
		};
		const unitError = {
			unitNameError : false,
			locationError : false,
			sqftError : false
		}
		if(newUnit.unitName.trim().length == "0"){
			console.log(newUnit.unitName.trim().length)
			unitError.unitNameError = true

		}
		if(newUnit.location == ""){
			unitError.locationError = true
		}
		if(newUnit.sqft == 0 || newUnit.sqft == "0"){
			unitError.sqftError = true
		}
		setNewUnitClass(unitError)
		if(unitError.unitNameError || unitError.locationError || unitError.sqftError){
			return;
		}
		
		user.apiCall('units', body, 'POST')
			.then((res) => {
				if (res.status === 200) {
					user.apiCall('units')
						.then(res => {
							setUnits(res.data);
							setLocationId('');
							setUnitToAdd('');
							setSqft(0);
							global.successToast(unitAddMsg);
						})
				}
				else {
					global.errorToast(unitFailMsg);
				}
				setNewUnit({ unitName: '', location: '', sqft: '' })
				setDisplayModal(false)
			}).catch((err)=>{
				global.errorToast(unitFailMsg);
			})
	}

	const updateUnit = (props) => {
		const body = {
			UnitId: props.newData.unitId,
			UnitName: props.newData.unitName.trim(),
			LocationId: props.newData.locationId,
			Sqft: props.newData.sqft
		}
		
		user.apiCall('units/' + props.newData.unitId, body, 'PUT')
			.then((res) => {
				if (res.status === 200) {
					user.apiCall('units')
						.then(res => {
							setUnits(res.data);
							setLocationId('');
							setUnitToAdd('');
							setSqft(0);
							global.successToast(unitUpdateMsg);
						})
				}
				else {
					global.errorToast(unitFailMsg);
				}
			})
			.catch((err)=>{
				global.errorToast(unitUpdateFailMsg);
			})
	}

	const deleteUnit = () => {
		user.apiCall('units/' + unitToDeleteId, {}, 'DELETE')
			.then((res) => {
				if (res.status === 200) {
					global.successToast(unitDeleteMsg);
					user.apiCall('units')
						.then(res => {
							setUnits(res.data)
						})
				}
				else {
					global.errorToast(unitDeleteFail);
				}
			})
			.catch((res) => {
				if (res.response.status === 400) {
					global.errorToast(unitAssignedFail);
				}
				else {
					global.errorToast(unitDeleteFail);
				}
			})
	}

	const inputTextEditor = (props, field) => (
		<InputText type="text"
			value={props.rowData[field]}
			onChange={(e) => props.editorCallback(e.target.value)}
			
		/>
	)


	const handleNumericChange = (props, e) => {

        props.editorCallback(e.value);
    };
	const inputNumericEditor = (props, field) => (
		<InputNumber value={props.rowData[field]} onChange={(e) => handleNumericChange(props, e)} />
	)

	const locationBodyTemplate = (rowData) => {
		return rowData.locationName;
	}

	const dropdownEditor = (props, field) => {
		return (
			<Dropdown value={props.rowData[field]}
				options={locations}
				optionLabel="locationName"
				optionValue="locationId"
				onChange={(e) => props.editorCallback(e.value) }
				style={{ width: '100%' }}
				placeholder="Select a Location"
				itemTemplate={(option) => {
					return <span >{option.locationName}</span>
				}}
				
			/>
		);
	}

	const onSqftEditComplete = (e) =>{
		console.log(e)
	}

	return (
		<div id="Units">
		<div className="p-grid">
			<Dialog style={{ minWidth: '25vw' }}
				header="Add New Unit"
				visible={displayModal}
				onHide={() => setDisplayModal(false)}
				footer={
					<Button label="Save"
						icon="pi pi-check"
						onClick={() => addUnit()}
						autoFocus
					/>
				}
			>
				<form >
					<p>
						<label htmlFor="locationNameModal">Unit Name:</label>
						<InputText id="locationNameModal"
							value={newUnit.unitName}
							onChange={(e) => {setNewUnit({ unitName: e.target.value, location: newUnit.location,sqft: newUnit.sqft }); if(!(e.value == ""))setNewUnitClass({...newUnitClass, unitNameError: false})}}
							style = {{borderColor : newUnitClass.unitNameError?'red':'#ced4da'}}/>
							{newUnitClass.unitNameError && <div style={{color:'red'}}>Unit Name cannot be empty</div>}
					</p>
					<p>
						<label htmlFor="locationDropdownModal">Location:</label>
						<Dropdown id="locationDropdownModal"
							value={newUnit.location}
							options={locations}
							optionLabel="locationName"
							optionValue="locationId"
							onChange={(e) =>{setNewUnit({ unitName: newUnit.unitName, location: e.value, sqft: newUnit.sqft }); if(!(e.value == ""))setNewUnitClass({...newUnitClass, locationError: false})}}
							placeholder="Select a Location"
							itemTemplate={(option) => {
								return <span >{option.locationName}</span>
							}} 
							style = {{borderColor : newUnitClass.locationError?'red':'#ced4da'}}/>
							{newUnitClass.locationError && <div style={{color:'red'}}>Please select a location</div>}
					</p>
					<p>
						<label htmlFor="sqftModal">SQFT:</label>

						<InputNumber id="sqftModal"
							className={newUnitClass.sqftError? 'error-border':'none'}
							style={{width: '100%'}}
							value={newUnit.sqft}
							onChange={(e) => {setNewUnit({ unitName: newUnit.unitName, location: newUnit.location, sqft: e.value }); if(e.value > '0')setNewUnitClass({...newUnitClass,sqftError: false})}} 
						/>
						{newUnitClass.sqftError && <div style={{color:'red'}}>Sqft cannot be zero</div>}

					</p>
				</form>
			</Dialog>
			<ConfirmDialog visible={displayDeleteDialog}
				onHide={() => setDisplayDeleteDialog(false)}
				message={"Do you want to delete " + unitToDelete + "?"}
				header="Delete Confirmation"
				icon="pi pi pi-info-circle"
				accept={() => deleteUnit()}
				reject={null}
			/>
			<div className="p-col-12">
				<div className="card">
					<h5>Units</h5>
					<div>
						<DataTable value={units}
							filterDisplay="row"
							editMode="row"
							onRowEditComplete={(props) => updateUnit(props)}
							sortOrder="1"
							responsiveLayout="scroll"
						>
							<Column alignHeader='center'
								align='center'
								field="unitName"
								filter
								filterPlaceholder="Search by name"
								filterMatchMode="contains"
								showFilterMenu={false}
								editor={(props) => inputTextEditor(props, 'unitName')}
								sortable
								header="Unit Name" >
							</Column>
							<Column alignHeader='center'
								align='center'
								field="locationId"
								filter
								filterPlaceholder="Search by name"
								filterMatchMode="contains"
								showFilterMenu={false}
								filterField="locationName"
								body={locationBodyTemplate}
								editor={(props) => dropdownEditor(props, 'locationId')}
								sortable
								header="Location" >
							</Column>
							<Column alignHeader='center'
								align='center'
								field="sqft"
								editor={(props) => inputNumericEditor(props, 'sqft')}
								sortable
								header="Sqft" >
							</Column>
							<Column alignHeader='center'
								align='center'
								rowEditor
								headerStyle={{ width: '10%', minWidth: '8rem' }}
								bodyStyle={{ textAlign: 'center' }}>
							</Column>
							<Column alignHeader='center'
								align='center'
								style={{ width: '10%' }}
								field="buttons"
								body={buttonBodyTemplate}
								header={<Button tooltip="Add Unit"
									tooltipOptions={{ position: 'left' }}
									onClick={() => setDisplayModal(true)}
									style={{ backgroundColor: "white", color: "#537dbf" }}
									label={<i className="pi pi-plus"></i>}
									className="p-button-rounded p-button-primary" />}>
							</Column>
						</DataTable>
					</div>
				</div>
			</div>
		</div>
		</div>
	);
}

